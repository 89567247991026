import { SelectChangeEvent } from "@mui/material/Select";
import { ChangeEvent, useEffect, Dispatch, useReducer } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import CustomSelect from "../../../../../shared/uiComponents/Dropdown";
import MultipleSelect from "../../../../../shared/uiComponents/Dropdown/multipleSelect";
import CustomInput from "../../../../../shared/uiComponents/Input";
import { Text } from "../../../../../shared/uiComponents";
import { useBehaviorTreatmentModificationOptions } from "../hook";
import { SessionBehaviorsInfo, SessionTargetsInfo, TimeInfo } from "../helpers";
import {
  ACTIONS,
  ActionProperties,
  ERRORS,
  behaviorTreatmentModificationErrorReducer,
  initialBehaviorTreatmentModificationErrorState,
} from "./behaviorTreatmentModificationReducer";
import { SaveBehaviorTreatmentModificationTypeProperties } from "../../SoapNoteTypesProperties/behaviorTreatmentModificationTypeProperties";
import { validateName } from "../../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { getLocations } from "../../../../../redux/State/clientSlice/soapNoteSlice";

const BehaviorTreatmentModificationData = ({
  data,
  setData,
  disabled = false,
}: {
  data: SaveBehaviorTreatmentModificationTypeProperties;
  setData: Dispatch<ActionProperties>;
  disabled?: boolean;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");

  const [errors, setErrors] = useReducer(
    behaviorTreatmentModificationErrorReducer,
    initialBehaviorTreatmentModificationErrorState
  );

  const {
    barriersList,
    interventionsList,
    reinforcersList,
    responseToInterventionList,
  } = useBehaviorTreatmentModificationOptions();

  const locationList = useSelector((state) => state.soapNote.locations);
  const { user } = useSelector((state) => state.session.sessionInfo);
  const errorList = useSelector((state) => state.soapNote.error.errors);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  useEffect(() => {
    const clientId = params.get("clientId");
    if (!!locationList || !clientId) return;
    dispatch(getLocations(clientId));
  }, [dispatch, locationList, params]);

  const locationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const interventionHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setIntervention });
    if (!errors.BehaviorTreatmentModificationInterventionResponses) return;
    setErrors({
      payload: false,
      type: ERRORS.BehaviorTreatmentModificationInterventionResponses,
    });
  };

  const responseToIntervationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setResponseToIntervation });
    if (!errors.ResponseToIntervention) return;
    setErrors({ payload: false, type: ERRORS.ResponseToIntervention });
  };

  const reinforcementHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setReinforcement });
    if (!errors.ReinforcementResponses) return;
    setErrors({ payload: false, type: ERRORS.ReinforcementResponses });
  };

  const bariersHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setBariers });
    if (!errors.BarrierToTreatmentResponses) return;
    setErrors({ payload: false, type: ERRORS.BarrierToTreatmentResponses });
  };

  const goalAddressedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setGoalsAddressed });
    if (!errors.GoalsAddressed) return;
    setErrors({ payload: false, type: ERRORS.GoalsAddressed });
  };

  const dataAnalyzedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setDataAnalyzed });
    if (!errors.DataAnalyzed) return;
    setErrors({ payload: false, type: ERRORS.DataAnalyzed });
  };

  const treatmentPlanUpdatesHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setTreatmentPlanUpdates });
    if (!errors.TreatmentPlanUpdates) return;
    setErrors({ payload: false, type: ERRORS.TreatmentPlanUpdates });
  };

  const malaptiveBehaviorsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setMaladaptiveBehaviorsObserved });
    if (!errors.MaladaptiveBehaviorsObserved) return;
    setErrors({ payload: false, type: ERRORS.MaladaptiveBehaviorsObserved });
  };

  const sessionSummaryHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setSessionSummary });
    if (!errors.SessionSummary) return;
    setErrors({ payload: false, type: ERRORS.SessionSummary });
  };

  const signatureHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setSignature });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) return;
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  return (
    <div>
      <TimeInfo disabled={disabled} />
      <CustomSelect
        label="Location:"
        data={locationList}
        value={!!data.locationId ? data.locationId : ""}
        setValue={locationHandler}
        className={"marginBottom16"}
        error={errors.LocationId}
        disabled={disabled}
      />
      <MultipleSelect
        label="Intervention in Session:"
        data={interventionsList}
        initialValue={data.behaviorTreatmentModificationInterventionResponses}
        setValue={interventionHandler}
        error={errors.BehaviorTreatmentModificationInterventionResponses}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomSelect
        label="Response to intervention: "
        data={responseToInterventionList}
        value={`${data.responseToIntervention}`}
        setValue={responseToIntervationHandler}
        className="marginBottom16"
        error={errors.ResponseToIntervention}
        disabled={disabled}
      />
      <MultipleSelect
        label="Reinforcers used during session:"
        initialValue={data.reinforcementResponses}
        data={reinforcersList}
        setValue={reinforcementHandler}
        className="marginBottom16"
        error={errors.ReinforcementResponses}
        disabled={disabled}
      />
      <MultipleSelect
        label="Barriers to Treatment:"
        data={barriersList}
        initialValue={data.barrierToTreatmentResponses}
        setValue={bariersHandler}
        className={"marginBottom16"}
        error={errors.BarrierToTreatmentResponses}
        disabled={disabled}
      />
      <CustomInput
        label="Goals addressed:"
        value={data.goalsAddressed}
        setValue={goalAddressedHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.GoalsAddressed}
        disabled={disabled}
      />
      <CustomInput
        label="Data analyzed:"
        value={data.dataAnalyzed}
        setValue={dataAnalyzedHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.DataAnalyzed}
        disabled={disabled}
      />
      <CustomInput
        label="Updates to treatment plan:"
        value={data.treatmentPlanUpdates}
        setValue={treatmentPlanUpdatesHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.TreatmentPlanUpdates}
        disabled={disabled}
      />
      <CustomInput
        label="Maladaptive behaviors observed"
        value={data.maladaptiveBehaviorsObserved}
        setValue={malaptiveBehaviorsHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.MaladaptiveBehaviorsObserved}
        disabled={disabled}
      />
      <Text
        title="Session Details"
        size="smallBold"
        className="marginBottom16 title"
      />
      <SessionTargetsInfo />
      <SessionBehaviorsInfo />
      <CustomInput
        label="Session Summary: "
        value={data.sessionSummary}
        setValue={sessionSummaryHandler}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.SessionSummary}
        disabled={disabled}
      />
      <div style={{ width: "250px" }}>
        <CustomInput
          label="Printed Name:"
          value={user.fullName.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <CustomInput
          label="Signature:"
          value={data.signature}
          setValue={signatureHandler}
          className="marginBottom8 signature"
          error={errors.Signature}
          disabled={disabled}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </div>
  );
};

export default BehaviorTreatmentModificationData;

import { FetchAPI } from "../Main/fetchAPI";
import { CLIENT, CLIENT_USERS, SOAP_NOTES } from "../constants";

import {
    SaveAssessmentTypeDataProperties,
    UpdateAssessmentTypeDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/assessmentTypeProperties";
import {
    SaveBehaviorTreatmentModificationTypeDataProperties,
    UpdateBehaviorTreatmentModificationTypeDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/behaviorTreatmentModificationTypeProperties";
import {
    SaveBehaviorTreatmentDataProperties,
    UpdateBehaviorTreatmentDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/behaviorTreatmentTypeProperties";
import {
    SaveDirectSupervisionDataProperties,
    UpdateDirectSupervisionDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/directSupervisionTypeProperties";
import {
    SaveParentTrainingTypeDataProperties,
    UpdateParentTrainingTypeDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/parentTrainingTypeProperties";
import {
    SaveTreatmentPlanningTypeDataProperties,
    UpdateTreatmentPlanningTypeDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/treatmentPlanningTypeProperties";
import {
    GetUserSoapNotesProperties,
    GetSoapNoteProperties,
    GetClientSoapNotesProperties,
    GetSoapNotesProperties,
    UpdateBillingCredentialedProperties,
} from "./soapNoteProperties";

export const SoapNote = {
    updateBillingCredentialed: (data: UpdateBillingCredentialedProperties) => FetchAPI.putJson({
        urlStr: `${CLIENT_USERS}/${data.userId}/soap-notes/update-billing-credentialed`,
        data,
    }),
    getSoapNotes: (data: GetSoapNotesProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            endDate,
            startDate,
        } = data;
        const query = new URLSearchParams();

        query.set('page', page);
        query.set('pageSize', pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);

        return FetchAPI.getJson({ urlStr: `${CLIENT}/soap-notes?${query.toString()}` })
    },
    getClientSoapNotes: (data: GetClientSoapNotesProperties) => {
        const {
            clientId,
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            startDate,
            endDate,
        } = data;
        const query = new URLSearchParams();

        query.set('page', page);
        query.set('pageSize', pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!startDate) query.set("startDate", startDate);
        if (!!endDate) query.set("endDate", endDate);

        return FetchAPI.getJson({
            urlStr: `${SOAP_NOTES(clientId)}?${query.toString()}`,
        })
    },
    getUserSoapNotes: (data: GetUserSoapNotesProperties) => {
        const {
            page,
            pageSize,
            userId,
            direction,
            orderBy,
            searchString,
            status,
            startDate,
            endDate,
        } = data;
        const query = new URLSearchParams();

        query.set('page', page);
        query.set('pageSize', pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!startDate) query.set("startDate", startDate);
        if (!!endDate) query.set("endDate", endDate);

        return FetchAPI.getJson({
            urlStr: `${CLIENT_USERS}/${userId}/soap-notes?${query.toString()}`,
        })
    },
    getSoapNote: ({ clientId, reportId }: GetSoapNoteProperties) => FetchAPI.getJson({
        urlStr: `${SOAP_NOTES(clientId)}/${reportId}`,
    }),
    getSoapNotePDF: ({ clientId, reportId }: GetSoapNoteProperties) => FetchAPI.getJson({
        urlStr: `${SOAP_NOTES(clientId)}/${reportId}/pdf`,
    }),
    unlockSoapNote: ({ clientId, reportId }: GetSoapNoteProperties) => FetchAPI.postJson({
        urlStr: `${SOAP_NOTES(clientId)}/${reportId}/unlock`,
    }),
    //Direct Supervision
    getDirectSupervisionOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);

        return FetchAPI.getJson({
            urlStr: `${SOAP_NOTES(clientId)}/direct-supervision/options?${query.toString()}`,
        })
    },
    saveDirectSupervision: ({ clientId, data }: SaveDirectSupervisionDataProperties) => FetchAPI.postJson({
        urlStr: `${SOAP_NOTES(clientId)}/direct-supervision`,
        data,
    }),
    updateDirectSupervision: ({ clientId, reportId, data }: UpdateDirectSupervisionDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${SOAP_NOTES(clientId)}/direct-supervision/${reportId}`,
            data,
        }),
    //Behavior Treatment
    getBehaviorTreatmentOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);

        return FetchAPI.getJson({
            urlStr: `${SOAP_NOTES(clientId)}/behavior-treatment/options?${query.toString()}`,
        })
    },
    saveBehaviorTreatment: ({ clientId, data }: SaveBehaviorTreatmentDataProperties) => FetchAPI.postJson({
        urlStr: `${SOAP_NOTES(clientId)}/behavior-treatment`,
        data,
    }),
    updateBehaviorTreatment: ({ clientId, reportId, data }: UpdateBehaviorTreatmentDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${SOAP_NOTES(clientId)}/behavior-treatment/${reportId}`,
            data,
        }),
    //Behavior Treatment Modification
    getBehaviorTreatmentModificationOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);

        return FetchAPI.getJson({
            urlStr: `${SOAP_NOTES(clientId)}/behavior-treatment-modification/options?${query.toString()}`,
        })
    },
    saveBehaviorTreatmentModification: ({ clientId, data }: SaveBehaviorTreatmentModificationTypeDataProperties) => FetchAPI.postJson({
        urlStr: `${SOAP_NOTES(clientId)}/behavior-treatment-modification`,
        data,
    }),
    updateBehaviorTreatmentModification: ({ clientId, reportId, data }: UpdateBehaviorTreatmentModificationTypeDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${SOAP_NOTES(clientId)}/behavior-treatment-modification/${reportId}`,
            data,
        }),
    //Assessment
    getAssessmentOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);
        return FetchAPI.getJson({
            urlStr: `${SOAP_NOTES(clientId)}/assessment/options?${query.toString()}`,
        })
    },
    saveAssessment: ({ clientId, data }: SaveAssessmentTypeDataProperties) => FetchAPI.postJson({
        urlStr: `${SOAP_NOTES(clientId)}/assessment`,
        data,
    }),
    updateAssessment: ({ clientId, reportId, data }: UpdateAssessmentTypeDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${SOAP_NOTES(clientId)}/assessment/${reportId}`,
            data,
        }),
    //Parent Training
    getParentTrainingOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);

        return FetchAPI.getJson({
            urlStr: `${SOAP_NOTES(clientId)}/parent-training/options?${query.toString()}`,
        })
    },
    saveParentTraining: ({ clientId, data }: SaveParentTrainingTypeDataProperties) => FetchAPI.postJson({
        urlStr: `${SOAP_NOTES(clientId)}/parent-training`,
        data,
    }),
    updateParentTraining: ({ clientId, reportId, data }: UpdateParentTrainingTypeDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${SOAP_NOTES(clientId)}/parent-training/${reportId}`,
            data,
        }),
    //Treatment Planning
    getTreatmentPlanningOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);
        return FetchAPI.getJson({
            urlStr: `${SOAP_NOTES(clientId)}/treatment-planning/options?${query.toString()}`,
        })
    },
    saveTreatmentPlanning: ({ clientId, data }: SaveTreatmentPlanningTypeDataProperties) => FetchAPI.postJson({
        urlStr: `${SOAP_NOTES(clientId)}/treatment-planning`,
        data,
    }),
    updateTreatmentPlanning: ({ clientId, reportId, data }: UpdateTreatmentPlanningTypeDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${SOAP_NOTES(clientId)}/treatment-planning/${reportId}`,
            data,
        }),
}
import { ChangeEvent, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import {
  AddOutlined as Add,
  DeleteOutlineOutlined as Delete,
} from "@mui/icons-material";
import { SelectChangeEvent } from "@mui/material";

import CustomButton from "../../../../../../shared/uiComponents/Button";
import Picker from "../../../../../../shared/uiComponents/DateAndTime";
import {
  dateNormalizer,
  errorNormalizer,
  validateNumber,
  validateDecimalNumber,
} from "../../../../../../shared/Helpers/functions";
import Text from "../../../../../../shared/uiComponents/Text";
import { useCompareDates } from "../../../../../../shared/Helpers/hooks";

import {
  CustomAlert,
  CustomInput,
  CustomSelect,
} from "../../../../../../shared/uiComponents";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import {
  getInsuranceAuthorizationSessionTypes,
  getInsuranceAuthorizations,
  saveTreatmentInsuranceAuthorizations,
} from "../../../../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import {
  AddAuthorizationDetailsProperties,
  AuthorizationProperties,
  InsuranceAuthorizationSessionTypeProperties,
} from "../../../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";
import { useCalculateHoursPerWeek } from "../hook";

interface DetailsInfoProperties extends AddAuthorizationDetailsProperties {
  name: string;
}

const TreatmentContent = ({ onClose }: { onClose: () => void }) => {
  const today = new Date();
  const todayDayjs = dayjs(today);
  const futureDateWeek = dayjs(today).add(1, "week");

  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const [authInfo, setAuthInfo] =
    useState<InsuranceAuthorizationSessionTypeProperties>({
      id: "",
      frequencies: [],
      name: "",
      supportAdditionalWeeklyHours: false,
    });
  const [frequency, setFrequency] = useState<number>(1);
  const [totalHours, setTotalHours] = useState<string>("");
  const [customHoursPerWeek, setCustomHoursPerWeek] = useState<string>("");

  const [startDateValue, setStartDateValue] = useState<Dayjs>(todayDayjs);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(futureDateWeek);
  const { hasError, message } = useCompareDates(startDateValue, endDateValue);
  const { hoursPerWeek, loading } = useCalculateHoursPerWeek({
    startDateValue,
    endDateValue,
    totalHours,
    frequency,
  });

  const [treatmentAuthorizationDetails, setTreatmentAuthorizationDetails] =
    useState<Array<DetailsInfoProperties>>([]);
  const [sessionTypesData, setSessionTypesData] = useState<
    Array<InsuranceAuthorizationSessionTypeProperties>
  >([]);
  const sessionTypes = useSelector(
    (state) => state.authorization.insuranceAuthorizationSessionTypes
  );

  useEffect(() => {
    if (!sessionTypes || !sessionTypes.length) return;
    const frequency = sessionTypes.find((x) => x.id === authInfo.id);
    if (!frequency) return;
    const frequencies = [...frequency.frequencies];
    setFrequency(
      frequencies.sort((a, b) => {
        if (a.frequency > b.frequency) return 1;
        if (a.frequency < b.frequency) return -1;
        return 0;
      })[0].id
    );
  }, [sessionTypes, authInfo]);

  const onChangeTreatmentStartDate = (value: Dayjs | null) => {
    if (!value) return;
    setStartDateValue(value);
  };

  const onChangeTreatmentEndDate = (value: Dayjs | null) => {
    if (!value) return;
    setEndDateValue(value);
  };

  const onChangeSessionType = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    const sessionTypeInfo = sessionTypes.find((x) => x.id === value);
    if (!sessionTypeInfo) return;
    setAuthInfo(sessionTypeInfo);
    setTotalHours("0");
  };

  const fetchAuthorizations = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    if (!clientId) return;
    dispatch(getInsuranceAuthorizations({ clientId, page, pageSize }));
  };

  const saveAuthorizationTreatment = () => {
    if (!startDateValue || !endDateValue || !clientId) return;

    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(endDateValue);

    const data: AuthorizationProperties = {
      startDate,
      endDate,
      authorizationDetails: treatmentAuthorizationDetails,
    };
    dispatch(saveTreatmentInsuranceAuthorizations({ clientId, data }))
      .then(unwrapResult)
      .then(() => {
        onClose();
        toast("Success");
        fetchAuthorizations();
      })
      .catch(errorNormalizer);
  };

  const frequencyHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (!validateNumber(value)) return;
    setFrequency(parseInt(value));
  };

  const onTotalHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value.replace(/^0+/, ""))) return;
    setTotalHours(value.replace(/^0+/, ""));
    setCustomHoursPerWeek("");
  };

  const onHoursPerWeekChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value.replace(/^0+/, ""))) return;
    if (!value.length) {
      setCustomHoursPerWeek("0");
      return;
    }
    setCustomHoursPerWeek(value.replace(/^0+/, ""));
  };

  const addTreatmentAuthorizationDetail = () => {
    if (!totalHours) return;
    const { id, name } = authInfo;
    const item: DetailsInfoProperties = {
      authorizationSessionTypeId: id,
      totalHours: parseFloat(totalHours),
      frequency: frequency,
      name,
      hoursPerWeek:
        frequency === 1
          ? !!customHoursPerWeek
            ? parseFloat(customHoursPerWeek)
            : hoursPerWeek
          : null,
    };
    setTreatmentAuthorizationDetails((prev) => [...prev, item]);
    const types = !sessionTypesData.length
      ? sessionTypes.filter((x) => x.id !== id)
      : sessionTypesData.filter((x) => x.id !== id);
    setSessionTypesData(types);
    setTotalHours("");
    setCustomHoursPerWeek("");
    setAuthInfo({
      frequencies: [],
      id: "",
      name: "",
      supportAdditionalWeeklyHours: false,
    });
  };

  const removeHandler = (sessionId: string) => {
    const items = treatmentAuthorizationDetails.filter(
      (x) => x.authorizationSessionTypeId !== sessionId
    );
    setTreatmentAuthorizationDetails(items);
    const type = sessionTypes.find((x) => x.id === sessionId);
    if (!type) return;
    const types = [...sessionTypesData, type];
    setSessionTypesData(types);
  };

  return (
    <>
      <Text
        title={"Treatment"}
        size="mediumBold"
        className="title marginBottom16 marginTop16"
      />
      <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
        <Picker.CustomDate
          label="Start Date:"
          value={startDateValue}
          onChange={onChangeTreatmentStartDate}
          className="marginBottom16 marginTop16"
          disabled={!!treatmentAuthorizationDetails.length}
        />
        <Picker.CustomDate
          label="End Date:"
          value={endDateValue}
          onChange={onChangeTreatmentEndDate}
          errorMessage={message}
          disabled={!!treatmentAuthorizationDetails.length}
          error={hasError}
        />
      </div>
      {treatmentAuthorizationDetails.map((x) => (
        <div
          style={{
            border: "1px solid black",
            borderRadius: "12px",
            position: "relative",
          }}
          className="padding8 marginBottom8"
        >
          <Text title={`Type: ${x.name}`} />
          <Text
            title={`Frequency: ${
              x.frequency === 1 ? "Weekly" : "Entire period"
            }`}
          />
          <Text title={`Hours: ${x.totalHours}`} />
          {x.frequency === 1 && (
            <Text title={`Weekly limit: ${x.hoursPerWeek}`} />
          )}
          <Delete
            className="margin8"
            onClick={() => removeHandler(x.authorizationSessionTypeId)}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
          />
        </div>
      ))}
      <div style={{ display: "flex", gap: "16px" }}>
        {treatmentAuthorizationDetails.length !== sessionTypes.length && (
          <div style={{ minWidth: "350px" }}>
            <CustomSelect
              label="Choose session type:"
              data={!!sessionTypesData.length ? sessionTypesData : sessionTypes}
              value={authInfo.id}
              setValue={onChangeSessionType}
              className="marginBottom16"
            />
          </div>
        )}
        {!!authInfo.id && (
          <CustomSelect
            label="Frequency"
            data={
              sessionTypes
                .find((x) => x.id === authInfo.id)
                ?.frequencies.map((x) => ({
                  id: `${x.id}`,
                  label: x.name,
                })) as Array<any>
            }
            value={`${frequency}`}
            className="width150"
            setValue={frequencyHandler}
          />
        )}
      </div>
      {!!authInfo.id && (
        <div style={{ display: "flex", gap: "16px" }} className="marginBottom8">
          <CustomInput
            label={`Total hours`}
            value={totalHours}
            setValue={onTotalHoursChange}
          />
          {!loading && (
            <div style={{ display: "flex", gap: "16px" }}>
              {frequency === 1 && (
                <CustomInput
                  label={`Weekly limit`}
                  value={
                    !!customHoursPerWeek ? customHoursPerWeek : hoursPerWeek
                  }
                  setValue={onHoursPerWeekChange}
                />
              )}
              <Add
                onClick={addTreatmentAuthorizationDetail}
                fontSize="large"
                className="marginTop24"
              />
            </div>
          )}
        </div>
      )}
      <CustomButton
        title="Save Treatment"
        onClick={saveAuthorizationTreatment}
        disabled={hasError || !treatmentAuthorizationDetails.length}
      />
    </>
  );
};

const AddTreatmentInsuranceAuthorization = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [open, setOpen] = useState<boolean>(false);

  const openHandler = () => {
    dispatch(getInsuranceAuthorizationSessionTypes("2"));
    setOpen(true);
  };
  const closeHandler = () => setOpen(false);

  return (
    <>
      <CustomButton title="Add Treatment" onClick={openHandler} />
      <CustomAlert
        open={open}
        onClose={closeHandler}
        popupHeight="550px"
        Content={({ onClose }) => <TreatmentContent onClose={onClose} />}
      />
    </>
  );
};

export default AddTreatmentInsuranceAuthorization;
